<template>
  <div class="Site">
    <!-- <div style="padding-left: 15px;padding-top: 10px">
      <img :src="`./yfc/logo.png`" width="100"></img>
    </div> -->
    <div class="flex_sb flex_ac top_div">
      <div class="top_text">
        <van-icon name="./skin/logo1.png" size="25"/>
      </div>
      <img class="top_img" src="~@/assets/img/new/kefu.png" alt="" @click="$router.push('serviceCenter')">
    </div>
    <div class="Home">
      <div class="header_link flex_ac flex_sb">
        <img class="tz_img1" src="~@/assets/img/new/tz_img.png" alt="">
        <van-notice-bar
            background="none"
            :text="noticeBarContent"
        />
<!--        <img class="tz_img2" src="~@/assets/img/new/tz_right.png" alt="">-->
      </div>
        <van-swipe :autoplay="3000"
                   :show-indicators="false"
                   class="homeswipe">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img ref="img" :src="item" class="BroadBarItem" width="100%" height="80%"/>
          </van-swipe-item>
        </van-swipe>
        <div class="menuPanel">
          <div style="width: 25%" @click="$router.push('/user/fundRecord')">
            <div>
              <img src="/skin/nav01.png" style="height: 2.6rem" />
            </div>
            <div>{{ $t('home.menu[0]') }}</div>
          </div>
          <div style="width: 25%" @click="$router.push('/user/mixrecharge')">
            <div>
              <img src="/skin/nav02.png" style="height: 2.6rem" />
            </div>
            <div>{{ $t('wallet.default[1]') }}</div>
          </div>
          <div style="width: 25%" @click="$router.push('/service')">
            <div class="four">
              <img src="/skin/nav03.png" style="height: 2.6rem" />
            </div>
            <div>{{ $t('home.menu[1]') }}</div>
          </div>
          <div @click="$router.push('/user/invite/'+UserInfo.idcode+'/'+UserInfo.idcode)" style="width: 25%">
            <div>
              <img src="/skin/nav04.png" style="height: 2.6rem" />
            </div>
            <div>{{ $t('home.menu[2]') }}</div>
          </div>
          <div style="width: 25%" @click="$router.push('/serviceCenter')">
            <div class="four">
              <img src="/skin/nav05.png" style="height: 2.6rem" />
            </div>
            <div>{{ $t('home.menu[3]') }}</div>
          </div>

      </div>
      <div class="tool">
        <div class="tool_item2" v-for="(item, index) in currency0" :key="index">
          <!--div class="">
            <img class="t_img" :src="`./static/currency/`+item.name.toLowerCase()+`.png`" alt="">
          </div-->
          <div class="flex_ac" v-if="item.rate>0" style="color: #55D3E4;font-size: 13px;font-weight: bold">
            <!--img style="width:10px;margin-right:6px" src="~@/assets/img/new/shang.png" alt=""-->
            {{'+' + parseFloat(item.rate).toFixed(2)}}%
          </div>
          <div class="flex_ac" v-else style="color: #FF6270;font-size: 13px;font-weight: bold">
            <!--img style="width:10px;margin-right:6px" src="~@/assets/img/new/xia.png" alt=""-->
            {{'-' + parseFloat(item.rate).toFixed(2)}}%
          </div>
          <div class="t_name" style="margin-top: 10px"> {{item.name}}<span class="t_text"> / {{InitData.usdtinfo.sign}}</span></div>
          <div style="font-size: 14px;font-weight: bold;margin:3px 0"> {{parseFloat(item.price).toFixed(2)}}</div>
        </div>

<!--        <div class="tool_item" v-for="(item, index) in currency0" :key="index">-->
<!--          <div class="flex_ac">-->
<!--            <img class="t_img" :src="`./static/currency/`+item.name.toLowerCase()+`.png`" alt="">-->
<!--            <div class="t_name"> {{item.name}}<span class="t_text"> / {{InitData.usdtinfo.sign}}</span></div>-->
<!--          </div>-->
<!--          <div style="font-size: 18px;font-weight: bold;margin:10px 0"> {{parseFloat(item.price).toFixed(2)}}</div>-->
<!--          <div class="flex_ac" v-if="item.rate>0" style="color: #55D3E4;font-size: 13px;font-weight: bold">-->
<!--            <img style="width:10px;margin-right:6px" src="~@/assets/img/new/shang.png" alt="">-->
<!--            {{'+' + parseFloat(item.rate).toFixed(2)}}%-->
<!--          </div>-->
<!--          <div v-else style="color: #FF6270;font-size: 13px;font-weight: bold">-->
<!--            <img style="width:10px;margin-right:6px" src="~@/assets/img/new/xia.png" alt="">-->
<!--            {{'-' + parseFloat(item.rate).toFixed(2)}}%-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <!--div style="font-size: 14px;display: flex">
        <div class="Menu2item2" style="background-color: var(--panel_color)" @click="gotoBuy">
          <div style="padding-left: 5px;margin-right: 5px;margin-top: 10px">
            <van-icon name="./skin/arrow2.png" size="40"/>
          </div>
          <div style="margin-left: 5px;width: 80%;">
            <div style="font-size: 14px;font-weight: bold;line-height: 30px;margin-bottom: 5px">{{ $t('home4[0]') }}</div>
            <van-button class="van-button-border">{{$t('hall.default[9]')}}</van-button>
          </div>
        </div>
        <div class="Menu2item2 Menu2item3" style="background-color: var(--panel_color)" @click="$router.push('/sell')">
          <div style="padding-left: 5px;margin-right: 5px;margin-top: 10px">
            <van-icon name="./skin/arrow1.png" size="40"/>
          </div>
          <div style="margin-left: 5px;width: 70%;">
            <div style="font-size: 14px;font-weight: bold;line-height: 30px;margin-bottom: 5px">{{ $t('home4[2]') }}</div>
            <van-button class="van-button-border2">{{$t('hall.default[10]')}}</van-button>
          </div>
        </div>
      </div-->
<!--      <div class="title">{{$t('home.special_attention')}}</div>-->

      <div class="homelist">
        <!--div class="title">{{$t('home.spot_market')}}</div-->
        <div v-for="(item, index) in currency2" class="coinitem">
          <table style="font-size:13px;width: 100%;padding:8px 5px 7px 0px">
            <tr>
              <!--td style="width: 10%">
                <img :src="`./static/currency/`+item.name.toLowerCase()+`.png`" style="height: 2.5rem;margin-right:10px" />
              </td-->
              <td style="font-size: 16px;width: 40%;padding-left: 10px">
                <div style="font-weight:bold;color: var(--font_nav_bar)">{{item.name}}</div>
                <div style="line-height: 20px;color: var(--font_subtitle)">{{$t('mytask2[1]')}}: {{$Util.formatNum(parseFloat(item.price0).toFixed(2))}}
                </div>
              </td>
              <td style="font-size: 16px;text-align: right">
                <div style="" class="rate1">+{{parseFloat(item.rate).toFixed(2)}}%</div>
                <div style="line-height: 20px;color: var(--font_subtitle)">
                </div>
              </td>

            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="h80"></div>
    <Footer />
    <van-popup
        v-model="showNotice"
        style="background: transparent; width: 85%; text-align: center"
        @closed="$parent.isNotice = true"
    >
      <div class="popupBg">
        <div style="margin: 0 auto;text-align: right;width: 100%;margin-top: -30px;">
          <van-image src="./skinlscc/pop_icon.png" width="100px" style="margin-right: 20px"/>
        </div>
        <dl
            class="ScrollBox NoticePopup"
            v-if="noticeContent.length>0"
            @click="$router.push(`/article/notice/${InitData.noticelist[0].id}`)"
        >

          <dd v-html="noticeContent" style="padding: 15px;color: #fff;word-break: break-word"></dd>
        </dl>
      </div>
      <a class="close" href="javascript:;" @click="showNotice = false">
        <img src="../../public/static/images/pupup_false.png" />
      </a>
    </van-popup>
  </div>
</template>


<script>
import $ from "jquery";
// import {ref} from 'vue'
export default {
  name: "Home",
  inject: ["reloadHtml"],
  components: {},
  props: ["user_id"],
  data() {
    return {
      currentRate: 0,
      showNotice: false,
      tabActive: 0, //任务大厅
      tabActive1: 0, //榜单
      currency0:[],
      currency2:[],
      noticeBarContent:"",
      language1: localStorage.getItem("Language") == "cn",
      bannerList: [
          "../static/images/bg_banner.png"
      ],
      noticeContent:'',
      moeny: "",
      currHeader:'head_6.png',
      timer: null, //定时器
      time1:0,
    };
  },

  computed: {
    text() {
      // return this.currentRate.toFixed(0) + '%'
      return "loading";
    },
  },
  watch: {
    "InitData.noticelist"(data) {
      if (!this.$parent.isNotice && data.length) this.showNotice = true;
    },
  },
  created() {
    if (!localStorage["Token"]) {
      this.$router.push("/login");
      return;
    }
    var a = this.InitData;
    // this.noticeContent = "ddddddddd";
    if (this.InitData.noticelist&&this.InitData.noticelist.length){
      this.noticeContent = this.InitData.noticelist[0].content;
    }
    window.callReadFunction = this.callReadFunction;
    if (localStorage["Token"]) {
      // 查询绑定银行卡
      this.$Model.GetBankCardList();
    }
    var ret = this.$Util.readAppStorage();
    console.log("欢迎");
    this.noticeBarContent = "";
    this.$Model.GetBackData((data) => {
      // this.bannerList = data.bannerList;
      this.usdtinfo = data.usdtinfo;
      for (let i=0;i<data.drawNotice.length;i++){
        this.noticeBarContent += data.drawNotice[i]['content']+"; "
      }
      this.noticeBarContent = this.noticeBarContent.replace("<p>","").replace("</p>","")
    });
    this.getPrices();
    // alert("xxxxxxxxxxxxx:"+ret)
  },
  mounted() {
    if (this.UserInfo.header){
      this.currHeader = this.UserInfo.header;
    }
    //获取浏览器地址
    let getHref = window.location.href;
    // console.log(this.$refs)
    // for(let i=0;i<)
  },
  activated() {},
  destroyed() {},
  // 离开页面清除倒计时
  beforeDestroy() {
    clearInterval(this.timer);
    console.log("clear timer done:"+this.timer);
  },
  methods: {
    gotoBuy(){
      let usertype = this.UserInfo.user_type;
      if (!usertype||usertype==0){
        this.$Dialog.Toast(this.$t("busAuth2[5]"));
        return;
      }else{
        this.$router.push("/buy");
      }
    },
    callReadFunction(data) {
      localStorage["AppData"] = data;
      // this.$toast(data)
    },
    getPrices() {
      var that = this;
      that.setCoinData(that.InitData);
      setTimeout(function() {
        that.timer = setInterval(() => {
          // console.log("get prices");
          that.getCommonData();
        }, 4000);
      }, 5000);
    },
    getCommonData(){
      var that = this;
      that.time1 = new Date().getTime();
      this.$Model.GetCommonData(data=>{
        that.setCoinData(data)
      });
    },
    setCoinData(data){
      var that = this;
      let time2 = new Date().getTime();
      if (!data.currencylist){
        return;
      }
      console.log("time cost00: "+(time2-that.time1))
      let coins = data.currencylist;
      if (coins.length<3){
        this.$Dialog.Toast("no currency found");
        return;
      }
      console.log("get prices done:")
      let coins0 = [];
      for (var i=0;i<3;i++){
        coins0.push(coins[i]);
      }
      this.currency0 = coins0;
      coins.forEach(coin=>{
        var ii = "star1";
        var kk = Math.round(Math.random()*10);
        if (kk>5){
          ii = "star2"
        }
        coin['star'] = ii;
      })
      this.currency2 = coins;
      time2 = new Date().getTime();
      console.log("time cost: "+(time2-that.time1))
    },
    goTask(index, state) {
      if (state == 3) {
        this.$toast.fail(this.$t("home.msg"));
      } else {
        let grade = this.UserInfo.vip_level;
        let taskType = this.InitData.taskclasslist.filter(
            (obj) => obj.state == 1
        )[index].group_id;
        /* this.$router.push(`/taskList/${taskType}/${grade}`); */
        this.$router.push("/GrabOrders");
      }
    },

    // 打招呼
    goChatPage(id) {
      this.$router.push(`/user/chatPage/${id}`);
    },
    toGrabtask() {
      console.log(1);
      this.$router.push("/user/Grabtask");
    },
  },
};
</script>
<style scoped lang="scss">
.Site {
  font-family: Alibaba;
  &::v-deep .van-swipe__indicators{
    left: auto;
    right:0 !important;
  }
  &::v-deep .van-swipe__indicator--active{
    width: 14px;
    border-radius: 3px;
  }
}
.top_div{
  width: 96%;
  margin-left: 2%;
  padding: 12px 0;
  .top_text{
    font-size: 18px;
  }
  .top_img{
    height: 20px;
    width: 20px;
  }
}

.ScrollBox{
  // background-color: #F5F8FF;
}
.BroadBarItem {
  width: 100%;
}


.header_link {
  padding: 0 15px;
  margin: 0px 10px;
  border-radius: 10px;
  color: var(--font_color);
  background: transparent;
  .van-notice-bar{
    color: var(--font_color);
    width: calc(100% - 50px);
  }
  .tz_img1{
    width: 28px;
  }
  .tz_img2{
    width: 10px;
  }
}

.menuPanel {
  display: flex;
  margin: 10px auto;
  margin-top: -20px;
  text-align: center;
  padding: 10px;
  width: 95%;
  margin-bottom: 30px;
  border-radius: 5px;
  font-size: 13px;
  background-color: var(--panel_color2);
}
.Menu2 ::v-deep .van-grid-item__content {
  /*background-color: #151d31;*/
  border-radius: 5px;
  color:#cccccc;
  background: transparent none repeat scroll 0% 0%;
}

.tool ::v-deep .van-grid-item__content {
  padding: 3px !important;
  display: flex;
  color: #D7D7D7;
}

.homeswipe {
  border-radius: 10px;
  width: 96%;
  margin-left: 2%;
  height: 12rem;
  z-index: 50;
  margin-top: 5px;
  margin-bottom: 0px;
}
.homelist {
  // background-color: #0F1A29;
  //background: var(--panel_color);
  border-radius: 10px;
  margin: 10px 2%;
  padding: 10px 0;
  margin-top: 0px;
}
.header ::v-deep .Menu .van-grid-item__content {
  padding: 5px;
  display: flex;
}

.header ::v-deep .Menu .van-grid-item__content div:first-child {
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header ::v-deep .Menu .van-grid-item__content div:last-child {
  margin-top: 0px;
  text-align: center;
}

.content_footer ::v-deep .van-cell::after {
  display: none;
}

.Menu2item2 {
  display: flex;
  height: 105px;
  width: 50%;
  padding: 5px;
  padding-top: 15px;
  text-align: left;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 14px;
  border-radius: 10px;
}

.Menu2item3 {
}
.tool::before {
  content: "";
  display: block;
}

.tool {
  border-radius: 10px;
  overflow-x: auto;
  z-index: 199;
  margin: 10px auto;
  text-align: center;
  //background-color: var(--panel_color);
  width: 95%;
  padding: 0 5px;
  white-space: nowrap;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 0;
    height: 0;
    border-radius: 0px;
    border: 0px solid transparent;
    background-clip: padding-box;
  }
  .tool_item2{
    display: inline-block;
    text-align: left;
    background: var(--panel_color3);
    border-radius: 20px;
    border-top-left-radius: 50px;
    border: 1px solid var(--border_color);
    width: 110px;
    padding: 10px;
    margin: 5px 15px 5px 5px;
    .t_img{
      height: 45px;
      width: 45px;
      margin-right: 8px;
    }
    .t_name{
      font-size: 15px;
      font-weight: 600;
    }
    .t_text{
      font-size: 15px;
      font-weight: 300;
      color: var(--font_subtitle);
    }
  }
}

.zh-CN ::v-deep .van-grid-item__icon-wrapper,
.zh-HK ::v-deep .van-grid-item__icon-wrapper {
  word-spacing: 5px;
}

.MemberList ::v-deep .van-tabs__wrap {
  height: 38px;
}

.MemberList ::v-deep .van-tabs__nav--card {
  margin: 0;
}

.MemberList ::v-deep .swipe-item_box {
  display: flex;
  align-items: center;
  padding: 10px 10px !important;
}

.swipe-item_box ::v-deep .swipe-item_info_title {
  margin-left: 15px;
  margin-top: 6px;
  height: 18px;
  font-size: 13px !important;
  font-family: Alibaba;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swipe-item_box ::v-deep .swipe-item_info_title span {
  color: #ff93a4;
}

.swipe-item_box ::v-deep .swipe-item_info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  overflow: hidden;
}

.swipe-item_box ::v-deep .swipe-item_info_details {
  display: flex;
  margin-top: 4px;
  align-items: flex-start;
  border-bottom: 1px solid #d0d0d0;
}

.swipe-item_box ::v-deep .swipe-item_info_details .item_state {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 12px;
  transform: scale(0.8);
  white-space: nowrap;
}
.swipe-item_box ::v-deep .swipe-item_info_details .item_state > p {
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.swipe-item_box ::v-deep .swipe-item_info_details .isState {
  color: #4e51bf !important;
}

.swipe-item_box ::v-deep .swipe-item_info_details .isState span {
  background-color: #ff93a4 !important;
}

.swipe-item_box ::v-deep .swipe-item_info_details .item_state span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #a0a0a0;
  margin-right: 3px;
  flex-shrink: 0;
  margin-top: 2px;
}

.swipe-item_box ::v-deep .swipe-item_info_details .item_site {
  color: #666;
  font-size: 12px;
  transform: scale(0.8);
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swipe-item_box ::v-deep .swipe-item_info_details .profit {
  display: flex;
  margin-left: auto;
  align-items: center;
  color: #ff93a4;
}

.swipe-item_box ::v-deep .swipe-item_info_details .profit img {
  margin-right: 2px;
}

.swipe-item_box ::v-deep .swipe-item_info_details .item_massage {
  margin-left: auto;
  width: 80px;
  padding: 3px 0;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ------------- */
.MemberList ::v-deep .swipe-item_img_box {
  background: linear-gradient(0deg, #4e51bf, #ff93a4);
  padding: 2px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}

.MemberList ::v-deep .swipe-item_img_box1 {
  background: gray;
  padding: 2px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}

.MemberList ::v-deep .swipe-item_img {
  width: 100%;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ffe9ce;
}

.MemberList ::v-deep .swipe-item_img img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.MemberList ::v-deep .van-cell__title {
  display: flex;
}

.MemberList ::v-deep .van-tab__text {
  /*font-size: 18px;*/
  font-weight: bold;
  display: flex;
  align-items: center;
}

.MemberList .van-swipe-item {
  padding: 1px 0;
}

.topItem {
  line-height: 1;
  /*background-color: #151d31;*/
  color:#fff;
}

.topItem .van-cell__left-icon {
  height: 46px;
  width: 46px;
  /*background-color: #fff;*/
  border-radius: 100%;
  overflow: hidden;
  padding: 2px;
}

.TaskHall_info ::v-deep .van-cell__left-icon {
  font-size: 14px;
}

.topItem .van-icon__image {
  width: 100%;
  height: 100%;
}

.topItem .van-cell__label {
  line-height: 1;
  margin-top: 0;
  color:#fff;
  font-size: 14px;
}

.topItem .profit {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 3px 8px;
  font-size: 13px;
  color: #0e1526;
}
.coinitem {
  margin-left: 10px;
  padding-left: 5px;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 5px;
  .rate1{
    color: var(--font_color);
  }
  .rate2{
    color: #FF6270;
  }
  border: 2px solid #212A32;
}
.topItem .profit img {
  margin-right: 5px;
}
.MyHeader {
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 72px;
  height: 72px;
}
.MyHeader_box {
  padding: 2px;
  overflow: hidden;
  margin-left: 10px;
  flex-shrink: 0;
}


.Menu ::v-deep .van-grid-item__content {
  /*background-color: #151d31;*/
  border-radius: 5px;
  color:#cccccc;
  background: transparent none repeat scroll 0% 0%;
}

.Menu ::v-deep .van-grid-item__icon-wrapper {
  margin-right: 15px;
  overflow: hidden;
  flex: 1;
}

.Title {
  /*background-color: #151d31;*/
  /*color: #fff;*/
  margin: 4px 0;
  padding: 7px 25px;
}

.popupBg {
  background: var(--popup-bg);
  border: 1px solid var(--border_color);
  border-radius: 20px;
}

.NoticePopup {
  background-size: contain;
  height: 450px;
  padding: 10px;
  padding-bottom: 20px;
}

.NoticePopup dd {
  font-size: 13.5px;
  background: var(--popup-content-bg);
  overflow: inherit;
  color: var(--font_color);
}

.popup_title {
  display: flex;
  line-height: normal;
  height: 70px;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 40px 0px 0 0;
}

.popup_title div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 26px;
}

.popup_title div span:first-child {
  font-weight: bold;
  color: #e7e7e7;
}

.popup_title div span:last-child {
  color: #e7e7e7;
  letter-spacing: 0.34rem;
  font-size: 22px;
}

.popup_title ::v-deep .letter_s {
  letter-spacing: 0.2rem;
  font-size: 25px !important;
}

.close img {
  width: 36px;
  margin-top: 20px;
}

.van-popup {
  overflow: inherit;
  /* height: 300px; */
}

.content_footer_justify ::v-deep .van-cell__left-icon {
  margin-right: 0;
}

.content_footer_justify ::v-deep .van-cell__left-icon img {
  width: 1.1em;
  height: 1.1em;
}

.content_footer_justify_itemBgc {
  background: linear-gradient(180deg, #4e51bf, #ff93a4);
  padding: 3px;
  border-radius: 16px;
  margin-top: 15px;
}

.van-grid-item {
  padding: 0 !important;
}

.Site ::v-deep .van-nav-bar__text {
  color: #fff;
  font-size: 15px;
  position: relative;
}

.van-nav-bar__text1 ::v-deep .van-nav-bar__text {
  letter-spacing: 0.09rem;
}

.Site ::v-deep .van-nav-bar__text::after {
  content: "";
  position: absolute;
  background: url(../../public/static/images/language_after.png) no-repeat;
  background-size: 100% 100%;
  width: 9px;
  height: 5px;
  top: 9px;
  right: -14px;
}

.Site ::v-deep .van-nav-bar__right {
  padding: 0;
  padding-right: 35px;
}

.TaskHall_info ::v-deep .van-cell__left-icon img {
  width: 11px;
  height: 11px;
}

.two img {
  width: 30px;
  height: 20px !important;
}
.title{
  margin: 0px 2%;
  font-size: 20px;
  color: var(--font_color);
  line-height: 40px;
  margin-bottom: 10px;
  border-bottom: 1px dashed;
}
</style>
